import { CF2Component } from 'javascript/lander/runtime'
import { debounce } from '../../Utils/general'

let fuse
const allProductsById = {}

const getCheapestPrice = (prices) => {
  return prices.reduce(
    (acc, value) => {
      if (acc.price_cents > value.price_cents) {
        acc.price_cents = value.price_cents
        acc.price = value.price
      }

      return acc
    },
    { price_cents: Infinity, price: '' }
  )
}

const parseProducts = (products) => {
  return products.map((p) => {
    let cheapestPrice = getCheapestPrice(p.default_variant.prices)
    if (p.variants.length > 1) {
      cheapestPrice = p.variants.reduce(
        (acc, value) => {
          const currentVariantCheapestPrice = getCheapestPrice(value.prices)
          if (currentVariantCheapestPrice.price_cents < acc.price_cents) {
            acc.price_cents = currentVariantCheapestPrice.price_cents
            acc.price = currentVariantCheapestPrice.price
          }

          return acc
        },
        { ...cheapestPrice }
      )
    }
    return { id: p.id, name: p.name, description: p.description, price: cheapestPrice.price }
  })
}

const prepareProductsForSearch = (component: CF2Component) => {
  return globalThis
    .CFFetch(
      '/cfworker/api/products/search',
      {
        headers: { 'Content-Type': 'application/json' },
      },
      { retries: 3, shouldCaptureServerError: true }
    )
    .then((res) => res.json())
    .then((json) => {
      const initialProducts = json.products
      if (!initialProducts || !initialProducts.length) return
      initialProducts.forEach((p) => (allProductsById[p.id] = p))
      const parsedProducts = parseProducts(initialProducts)
      fuse = new globalThis.Fuse(parsedProducts, {
        threshold: 0.5,
        keys: ['name', { name: 'description', weight: 0.5 }],
        includeMatches: true,
      })
      productsReady(component)
    })
}

const highlightSearchOnProduct = (product: any) => {
  const set = (obj: any, path: string, value: any) => {
    const pathValue = path.split('.')
    let i

    for (i = 0; i < pathValue.length - 1; i++) {
      obj = obj[pathValue[i]]
    }

    obj[pathValue[i]] = value
  }

  const generateHighlightedText = (inputText: string, regions: number[] = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0

    regions.forEach((region) => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        '<span class="elStoreSearchHighlight">',
        inputText.substring(region[0], lastRegionNextIndex),
        '</span>',
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }
  const { item, matches } = product
  const highlightedItem = { ...item }

  matches.forEach((match: any) => {
    set(highlightedItem, match.key, generateHighlightedText(match.value, match.indices))
  })

  return highlightedItem
}

const searchProducts = ([evt, component]) => {
  const search = (evt.target as HTMLInputElement).value
  if (search) {
    const foundProducts = fuse.search(search)
    if (foundProducts.length) {
      const products = foundProducts
        .filter(({ matches }: any) => matches && matches.length)
        .map((p) => {
          const product = highlightSearchOnProduct(p)
          const originalProduct = allProductsById[product.id]
          const image = originalProduct.image ?? originalProduct.variants.find((variant) => !!variant.image)?.image
          return {
            ...product,
            url: originalProduct.url,
            image: image,
          }
        })
      updateProducts(component, products)
      hideTitle(component)
      showSearchProducts(component)
    } else {
      hideProducts(component)
      hideTitle(component)
      showResultsNotFound(component, search)
    }
  } else {
    updateProducts(component, Object.values(allProductsById).slice(0, 6))
    getProductsElementComponent(component).element.classList.remove('forceHide')
    hideResultsNotFound(component)
    showTitle(component)
  }
}

const debounceHandler = debounce(searchProducts, 700)

export const mountComponent = (component: CF2Component): void => {
  prepareProductsForSearch(component)
  const searchIcon = component.element.querySelector('[href="#open-store-search"]')
  searchIcon.addEventListener('click', () => showModal(component))

  const searchElement = component.element.querySelector('[name="store-search"]')
  searchElement.addEventListener('input', (evt) => debounceHandler(evt, component))

  const modal = component.getComponent('Modal/V1')
  modal.element.addEventListener('click', () => {
    hideModal(component)
  })

  component.element.querySelector('.elStoreSearch').addEventListener('click', (evt) => {
    evt.stopPropagation()
  })

  component.element.querySelectorAll('.elModalClose').forEach((elem) =>
    elem.addEventListener('click', () => {
      hideModal(component)
    })
  )

  document.addEventListener('keydown', (evt) => {
    if (evt.key === 'Escape') hideModal(component)
  })
}

const getProductsElementComponent = (component: CF2Component) => {
  return component.getComponent('StoreSearchProducts')
}

const updateProducts = (component: CF2Component, products) => {
  ;(getProductsElementComponent(component) as any).search_products = products
  getProductsElementComponent(component).render()
}

const showModal = (component: CF2Component) => {
  document.documentElement.classList.add('hide-page-scroll')
  component.getComponent('Modal/V1').element.style.display = 'flex'
  component.element.querySelector('.elStoreSearch').setAttribute('data-animation-state', 'running')
  ;(component.element.querySelector('[name="store-search"]') as HTMLInputElement).focus()
}

const hideModal = (component: CF2Component) => {
  document.documentElement.classList.remove('hide-page-scroll')
  component.getComponent('Modal/V1').element.style.display = 'none'
}

const productsReady = (component: CF2Component) => {
  getProductsElementComponent(component).element.classList.remove('forceHide')
  component.element.querySelector('.elStoreSearchTitle').classList.remove('forceHide')
  ;(component.element.querySelector('[name="store-search"]') as HTMLInputElement).disabled = false
  component.element.querySelector('.elStoreSearchLoading').classList.add('forceHide')
}

const showSearchProducts = (component: CF2Component) => {
  getProductsElementComponent(component).element.classList.remove('forceHide')
}

const hideProducts = (component: CF2Component) => {
  getProductsElementComponent(component).element.classList.add('forceHide')
}

const hideTitle = (component: CF2Component) => {
  component.element.querySelector('.elStoreSearchTitle').classList.add('forceHide')
}

const showTitle = (component: CF2Component) => {
  component.element.querySelector('.elStoreSearchTitle').classList.remove('forceHide')
}

const showResultsNotFound = (component: CF2Component, text: string) => {
  const [split1, split2] = (component as any).not_found_text.split('{search}')
  component.element.querySelector('.elStoreSearchResultsNotFound').innerHTML = split1 + text + split2
  component.element.querySelector('.elStoreSearchResultsNotFound').classList.remove('forceHide')
}

const hideResultsNotFound = (component: CF2Component) => {
  component.element.querySelector('.elStoreSearchResultsNotFound').classList.add('forceHide')
}
