

import { CF2Component } from 'javascript/lander/runtime'

export default class ContentNode extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount(el) {
    this.syncSameNameInputs();
  }

  syncSameNameInputs() {
    const formInputs = document.querySelectorAll(".elFormItem[name]");
    formInputs.forEach((input) => {
      const inputName = input.name;
      input.addEventListener("change", () => {
        if (input.type === "checkbox" || input.type === "radio") {
          const inputValue = input.value;
          const otherInputs = document.querySelectorAll(
            `.elFormItem.elInput[name="${inputName}"][value="${inputValue}"]`
          );
          otherInputs.forEach((otherInput) => {
            otherInput.checked = input.checked;
          });
        } else {
          const otherInputs = document.querySelectorAll(
            `.elFormItem.elInput[name="${inputName}"]`
          );
          otherInputs.forEach((otherInput) => {
            otherInput.value = input.value;
          });
        }
      });
    });
  }



}

window["ContentNode"] = ContentNode

